<template>
  <div
    class="MazeBlock border relative border-gray-500"
    @mouseenter="onBlockTrek"
    :class="[
      show ? { N, S, E, W } : 'border-opacity-10',
      { 'bg-gray-400': !show || open },
      { 'bg-blue-100': show && isTrekked && !isSolved },
      { 'bg-red-500': show && isSolved },
      { 'border-green-300': debug && show && isConnector },
      { 'border-blue-300': debug && show && isTerminal },
      { 'border-purple-300': debug && show && isConnectorlessTerminal },
      { 'border-red-300': debug && show && isDead },
    ]"
    style="padding-top: 100%"
  >
    <div
      v-if="isVeryStart || isVeryEnd"
      class="absolute top-1/2 left-1/2 h-3 w-3 transform -translate-x-1/2 -translate-y-1/2 duration-500"
      :class="[show ? 'opacity-100 delay-1000' : 'opacity-0']"
    >
      <div class="animate-ping absolute inset-0 bg-red-500 rounded-full"></div>
      <div class="absolute inset-0 bg-red-500 rounded-full"></div>
    </div>
    <div
      v-if="debug && show"
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-sm text-black"
    >
      {{ space }}
    </div>
  </div>
</template>

<script>
const CARDS = ['N', 'S', 'E', 'W'];
const UNCARDS = ['S', 'N', 'W', 'E'];
const SEMICARDS = ['NE', 'NW', 'SE', 'SW'];

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default {
  name: 'MazeBlock',

  props: [
    'debug',
    'addToPath',
    'getBlock',
    'getBlockByXY',
    'index',
    'veryEnd',
    'veryStart',
    'pathEnd',
    'pathStart',
    'lastPath',
    'level',
    'time',
    'difficulty',
    'resetSpaces',
    'maxX',
    'maxY',
    'minX',
    'minY',
    'show',
    'version',
    'solutionIsComplete',
    'onBlockTrek',
  ],

  data() {
    return {
      N: false,
      S: false,
      E: false,
      W: false,
      space: null,
      isVeryStart: false,
      isVeryEnd: false,
      shouldFillSpace: false,
      isTrekked: false,
      isSolved: false,
      setN: (val = true) => (this.N = val),
      setS: (val = true) => (this.S = val),
      setE: (val = true) => (this.E = val),
      setW: (val = true) => (this.W = val),
      setSpace: (index) => (this.space = index),
    };
  },

  computed: {
    lastDir() {
      return this.lastPath[this.lastPath.length - 1]?.dir;
    },

    isOuterPerimeter() {
      let dir = [];
      if (this.getN === null) dir.push('N');
      if (this.getS === null) dir.push('S');
      if (this.getE === null) dir.push('E');
      if (this.getW === null) dir.push('W');
      return dir.length ? dir : false;
    },

    isInnerPerimeter() {
      if (!this.open || this.isOuterPerimeter) return false;
      let dir = [];
      if (this.getN !== null && !this.openN) dir.push('N');
      if (this.getS !== null && !this.openS) dir.push('S');
      if (this.getE !== null && !this.openE) dir.push('E');
      if (this.getW !== null && !this.openW) dir.push('W');
      if (this.getNE !== null && !this.openNE) dir.push('NE');
      if (this.getNW !== null && !this.openNW) dir.push('NW');
      if (this.getSE !== null && !this.openSE) dir.push('SE');
      if (this.getSW !== null && !this.openSW) dir.push('SW');
      return dir.length ? dir : false;
    },

    isConnector() {
      if (!this.open || this.isDead) return false;
      let isOneWay = (!this.openN && !this.openS) || (!this.openE && !this.openW);
      let isOneWayTurn =
        (!this.openN && !this.openW && !this.openSE) ||
        (!this.openS && !this.openW && !this.openNE) ||
        (!this.openN && !this.openE && !this.openSW) ||
        (!this.openS && !this.openE && !this.openNW);
      return isOneWay || isOneWayTurn;
    },

    isTerminal() {
      if (!this.open || this.isConnector || this.isDead) return false;

      let hasNeighborConnector =
        this.getN?.el?.isConnector ||
        this.getS?.el?.isConnector ||
        this.getE?.el?.isConnector ||
        this.getW?.el?.isConnector;

      let hasNeighborDead =
        this.getN?.el?.isDead ||
        this.getS?.el?.isDead ||
        this.getE?.el?.isDead ||
        this.getW?.el?.isDead;

      return hasNeighborConnector || hasNeighborDead;
    },

    isConnectorlessTerminal() {
      if (!this.open || this.isConnector || this.isDead) return false;
      return this.isTerminal && !this.hasConnectorNeighbor;
    },

    isDead() {
      if (!this.open) return false;
      let hasThreeSidesClosed =
        [!this.openN, !this.openS, !this.openE, !this.openW].filter((open) => open).length > 2;
      return hasThreeSidesClosed;
    },

    connectorNeighbors() {
      return this.neighbors.filter((block) => block?.el?.isConnector);
    },

    terminalNeighbors() {
      return this.neighbors.filter((block) => block?.el?.isTerminal);
    },

    deadNeighbors() {
      return this.neighbors.filter((block) => block?.el?.isDead);
    },

    neighbors() {
      return [this.getN, this.getS, this.getE, this.getW];
    },

    openNeighbors() {
      return this.neighbors.filter((block) => block?.el.open);
    },

    hasTrekkedNeighborPath() {
      return (
        (this.getN?.el?.S && this.getN?.el?.isTrekked) ||
        (this.getS?.el?.N && this.getS?.el?.isTrekked) ||
        (this.getE?.el?.W && this.getE?.el?.isTrekked) ||
        (this.getW?.el?.E && this.getW?.el?.isTrekked)
      );
    },

    hasConnectorNeighbor() {
      let neighbors = [];
      if (this.getN?.el?.isConnector) neighbors.push('N');
      if (this.getS?.el?.isConnector) neighbors.push('S');
      if (this.getE?.el?.isConnector) neighbors.push('E');
      if (this.getW?.el?.isConnector) neighbors.push('W');
      return neighbors.length ? neighbors : false;
    },

    hasTerminalNeighbor() {
      let neighbors = [];
      if (this.getN?.el?.isTerminal) neighbors.push('N');
      if (this.getS?.el?.isTerminal) neighbors.push('S');
      if (this.getE?.el?.isTerminal) neighbors.push('E');
      if (this.getW?.el?.isTerminal) neighbors.push('W');
      return neighbors.length ? neighbors : false;
    },

    hasDeadNeighbor() {
      let neighbors = [];
      if (this.getN?.el?.isDead) neighbors.push('N');
      if (this.getS?.el?.isDead) neighbors.push('S');
      if (this.getE?.el?.isDead) neighbors.push('E');
      if (this.getW?.el?.isDead) neighbors.push('W');
      return neighbors.length ? neighbors : false;
    },

    hasPathEndNeighbor() {
      if (this.getN?.el?.isPathEnd) return 'N';
      if (this.getS?.el?.isPathEnd) return 'S';
      if (this.getE?.el?.isPathEnd) return 'E';
      if (this.getW?.el?.isPathEnd) return 'W';
      return false;
    },

    hasOpenNeighbors() {
      let neighbors = [];
      if (this.getN?.el?.open) neighbors.push('N');
      if (this.getS?.el?.open) neighbors.push('S');
      if (this.getE?.el?.open) neighbors.push('E');
      if (this.getW?.el?.open) neighbors.push('W');
      return neighbors.length ? neighbors : false;
    },

    hasClosedNeighbors() {
      let neighbors = [];
      if (this.getN?.el && !this.getN?.el?.open) neighbors.push('N');
      if (this.getS?.el && !this.getS?.el?.open) neighbors.push('S');
      if (this.getE?.el && !this.getE?.el?.open) neighbors.push('E');
      if (this.getW?.el && !this.getW?.el?.open) neighbors.push('W');
      return neighbors.length ? neighbors : false;
    },

    makesConnectorFromN() {
      if (!this.open || this.isDead || this.isConnector) return false;
      let isOneWay = !this.openS;
      let isOneWayTurn = (!this.openW && !this.openSE) || (!this.openE && !this.openSW);
      let makesConnectorlessTerminal = this.isConnector;
      return isOneWay || isOneWayTurn || makesConnectorlessTerminal;
    },

    makesConnectorFromNE() {
      if (!this.open || this.isDead) return false;
      let isOneWayTurn = !this.openS && !this.openW;
      let hasXOpen = this.openE && this.openW;
      let hasYOpen = this.openN && this.openS;
      let makesConnectorlessTerminal = !this.openSW && (hasXOpen || hasYOpen);
      return isOneWayTurn || makesConnectorlessTerminal;
    },

    makesConnectorFromNW() {
      if (!this.open || this.isDead) return false;
      let isOneWayTurn = !this.openS && !this.openE;
      let hasXOpen = this.openE && this.openW;
      let hasYOpen = this.openN && this.openS;
      let makesConnectorlessTerminal = !this.openSE && (hasXOpen || hasYOpen);
      return isOneWayTurn || makesConnectorlessTerminal;
    },

    makesConnectorFromS() {
      if (!this.open || this.isDead || this.isConnector) return false;
      let isOneWay = !this.openN;
      let isOneWayTurn = (!this.openW && !this.openNE) || (!this.openE && !this.openNW);
      let makesConnectorlessTerminal = this.isConnector;
      return isOneWay || isOneWayTurn || makesConnectorlessTerminal;
    },

    makesConnectorFromSE() {
      if (!this.open || this.isDead) return false;
      let isOneWayTurn = !this.openN && !this.openW;
      let hasXOpen = this.openE && this.openW;
      let hasYOpen = this.openN && this.openS;
      let makesConnectorlessTerminal = !this.openNW && (hasXOpen || hasYOpen);
      return isOneWayTurn || makesConnectorlessTerminal;
    },

    makesConnectorFromSW() {
      if (!this.open || this.isDead) return false;
      let isOneWayTurn = !this.openN && !this.openE;
      let hasXOpen = this.openE && this.openW;
      let hasYOpen = this.openN && this.openS;
      let makesConnectorlessTerminal = !this.openNE && (hasXOpen || hasYOpen);
      return isOneWayTurn || makesConnectorlessTerminal;
    },

    makesConnectorFromE() {
      if (!this.open || this.isDead || this.isConnector) return false;
      let isOneWay = !this.openW;
      let isOneWayTurn = (!this.openN && !this.openSW) || (!this.openS && !this.openNW);
      let makesConnectorlessTerminal = this.isConnector;
      return isOneWay || isOneWayTurn || makesConnectorlessTerminal;
    },

    makesConnectorFromW() {
      if (!this.open || this.isDead || this.isConnector) return false;
      let isOneWay = !this.openE;
      let isOneWayTurn = (!this.openN && !this.openSW) || (!this.openS && !this.openNW);
      let makesConnectorlessTerminal = this.isConnector;
      return isOneWay || isOneWayTurn || makesConnectorlessTerminal;
    },

    makesConnectorToN() {
      return (
        this.getNN?.el?.makesConnectorFromS ||
        this.getNNE?.el?.makesConnectorFromSW ||
        this.getNNW?.el?.makesConnectorFromSE ||
        this.getNE?.el?.makesConnectorFromW ||
        this.getNW?.el?.makesConnectorFromE ||
        this.getE?.el?.makesConnectorFromNW ||
        this.getW?.el?.makesConnectorFromNE
      );
    },

    makesConnectorToS() {
      return (
        this.getSS?.el?.makesConnectorFromN ||
        this.getSSE?.el?.makesConnectorFromNW ||
        this.getSSW?.el?.makesConnectorFromNE ||
        this.getSE?.el?.makesConnectorFromW ||
        this.getSW?.el?.makesConnectorFromE ||
        this.getE?.el?.makesConnectorFromSW ||
        this.getW?.el?.makesConnectorFromSE
      );
    },

    makesConnectorToE() {
      return (
        this.getEE?.el?.makesConnectorFromW ||
        this.getENE?.el?.makesConnectorFromSW ||
        this.getESE?.el?.makesConnectorFromNW ||
        this.getNE?.el?.makesConnectorFromS ||
        this.getSE?.el?.makesConnectorFromN ||
        this.getN?.el?.makesConnectorFromNW ||
        this.getS?.el?.makesConnectorFromSW
      );
    },

    makesConnectorToW() {
      return (
        this.getWW?.el?.makesConnectorFromE ||
        this.getWNW?.el?.makesConnectorFromSE ||
        this.getWSW?.el?.makesConnectorFromNE ||
        this.getNW?.el?.makesConnectorFromS ||
        this.getSW?.el?.makesConnectorFromN ||
        this.getN?.el?.makesConnectorFromNE ||
        this.getS?.el?.makesConnectorFromSE
      );
    },

    open() {
      return !(this.N || this.S || this.E || this.W);
    },

    openN() {
      return this.getN?.el?.open;
    },
    openNE() {
      return this.getNE?.el?.open;
    },
    openNW() {
      return this.getNW?.el?.open;
    },
    openS() {
      return this.getS?.el?.open;
    },
    openSE() {
      return this.getSE?.el?.open;
    },
    openSW() {
      return this.getSW?.el?.open;
    },
    openE() {
      return this.getE?.el?.open;
    },
    openW() {
      return this.getW?.el?.open;
    },
    getN() {
      return this.getBlock(0, -1);
    },
    getNE() {
      return this.getBlock(1, -1);
    },
    getNW() {
      return this.getBlock(-1, -1);
    },
    getS() {
      return this.getBlock(0, 1);
    },
    getSE() {
      return this.getBlock(1, 1);
    },
    getSW() {
      return this.getBlock(-1, 1);
    },
    getE() {
      return this.getBlock(1, 0);
    },
    getW() {
      return this.getBlock(-1, 0);
    },

    getNN() {
      return this.getBlock(0, -2);
    },
    getNNE() {
      return this.getBlock(1, -2);
    },
    getNNW() {
      return this.getBlock(-1, -2);
    },

    getSS() {
      return this.getBlock(0, 2);
    },
    getSSE() {
      return this.getBlock(1, 2);
    },
    getSSW() {
      return this.getBlock(-1, 2);
    },

    getEE() {
      return this.getBlock(2, 0);
    },
    getENE() {
      return this.getBlock(2, -1);
    },
    getESE() {
      return this.getBlock(2, 1);
    },

    getWW() {
      return this.getBlock(-2, 0);
    },
    getWNW() {
      return this.getBlock(-2, -1);
    },
    getWSW() {
      return this.getBlock(-2, 1);
    },
  },

  watch: {
    version(newVersion, oldVersion) {
      if (newVersion !== oldVersion) {
        this.reset();
        this.$nextTick(() => {
          this.setEndPoints();
        });
      }
    },

    solutionIsComplete(val) {
      this.shouldFillSpace = val;
    },
  },

  beforeUnmount() {},

  mounted() {
    this.setEndPoints();
  },

  methods: {
    reset() {
      this.N = false;
      this.S = false;
      this.E = false;
      this.W = false;
      this.space = null;
      this.isTrekked = false;
      this.isSolved = false;
      this.shouldFillSpace = false;
    },

    setEndPoints() {
      let block = this.getBlock();

      let [veryStartX, veryStartY] = this.veryStart;
      let [veryEndX, veryEndY] = this.veryEnd;
      this.isVeryStart = block.x === veryStartX && block.y === veryStartY;
      this.isVeryEnd = block.x === veryEndX && block.y === veryEndY;

      let [pathStartX, pathStartY] = this.pathStart;
      let [pathEndX, pathEndY] = this.pathEnd;
      this.isPathStart = block.x === pathStartX && block.y === pathStartY;
      this.isPathEnd = block.x === pathEndX && block.y === pathEndY;
    },

    async go() {
      let canGo = [];
      let cannotGo = [];
      let ratherNotGo = [];
      let finalDir = '';
      let block = this.getBlock();

      // It's more difficult to solve when you don't make connectors
      let skipMakesConnector = false;
      // if (this.difficulty === 1) skipMakesConnector = false;
      if (this.difficulty === 2) skipMakesConnector = getRandomInt(0, 1) && getRandomInt(0, 1);
      if (this.difficulty === 3) skipMakesConnector = getRandomInt(0, 1);
      if (this.difficulty === 4) skipMakesConnector = getRandomInt(0, 1) || getRandomInt(0, 1);
      if (this.difficulty === 5) skipMakesConnector = true;

      for (var i = 0; i < this.hasOpenNeighbors.length; i++) {
        let card = this.hasOpenNeighbors[i];
        let nextBlock = this[`get${card}`];
        let nextEl = nextBlock?.el;
        if (!nextEl) continue;

        let go = `go${card}`;
        let unGo = `go${UNCARDS[i]}`;

        if (this.shouldFillSpace) {
          await this.findSpaces();
          if (nextEl.isDead) {
            if (nextEl.space === 1) {
              this.addToPath(card, block);
              return await this[go]();
            }
          } else {
            if (nextEl.space === 1) {
              canGo.push(card);
            }
          }
        } else {
          if (nextEl.isPathEnd) {
            canGo.push(card);
          } else if (nextEl.isDead) {
            await this.findSpaces();
            if (nextEl.space === 1) {
              canGo.push(card);
            }
          } else if (this[`makesConnectorTo${card}`]) {
            // TODO: go, findSpaces, calculateSpacesSize, unGo, unSetSpace
            // If space is big enough, then canGo.push(card), else ratherNotGo.push(card)
            await this.findSpaces();
            if (nextEl.space === 1) {
              if (skipMakesConnector) {
                ratherNotGo.push(card);
              } else {
                canGo.push(card);
              }
            }
          } else if (nextEl.isTerminal) {
            await this.findSpaces();
            if (nextEl.space === 1) canGo.push(card);
          } else if (nextEl.isConnector) {
            await this.findSpaces();
            if (nextEl.space === 1) {
              if (this.difficulty >= 3) {
                ratherNotGo.push(card);
              } else {
                canGo.push(card);
              }
            }
          } else {
            await this.findSpaces();
            if (nextEl.space === 1) canGo.push(card);
          }
        }
      }

      // Remove any paths it shouldn't go
      if (cannotGo.length) {
        for (var i = 0; i < cannotGo.length; i++) {
          let index = canGo.indexOf(cannotGo[i]);
          if (index !== -1) canGo.splice(index, 1);
        }
      }

      if (canGo.length === 0 && ratherNotGo.length) {
        canGo = ratherNotGo;
      }

      if (canGo.length) {
        // Weight my chances for a particular direction based on level
        if (canGo.length > 1 && canGo.indexOf(this.lastDir) !== -1) {
          let lastArr = this.lastDir.split('');
          canGo = canGo.concat(Array(11 - this.level).fill(lastArr[lastArr.length - 1]));
        }

        // Choose a direction
        finalDir = canGo[getRandomInt(0, canGo.length - 1)];
        let lastBlock = block;
        if (!Array.isArray(finalDir) && finalDir.length > 1) {
          finalDir = finalDir.split('');
        }
        if (Array.isArray(finalDir)) {
          // Take an array of predefined directions
          for (var i = 0; i < finalDir.length; i++) {
            this.addToPath(finalDir[i], block);
            lastBlock = await lastBlock.el[`go${finalDir[i]}`]();
          }
        } else {
          this.addToPath(finalDir, block);
          // Just go the one direction set
          lastBlock = await this[`go${finalDir}`]();
        }
        return lastBlock;
      }
    },

    async goN(use = true) {
      let nextBlock = this.getN;
      if (!nextBlock?.el) return;
      this.setN(use);
      nextBlock.el.setS(use);
      return nextBlock;
    },

    async goS(use = true) {
      let nextBlock = this.getS;
      if (!nextBlock?.el) return;
      this.setS(use);
      nextBlock.el.setN(use);
      return nextBlock;
    },

    async goE(use = true) {
      let nextBlock = this.getE;
      if (!nextBlock?.el) return;
      this.setE(use);
      nextBlock.el.setW(use);
      return nextBlock;
    },

    async goW(use = true) {
      let nextBlock = this.getW;
      if (!nextBlock?.el) return;
      this.setW(use);
      nextBlock.el.setE(use);
      return nextBlock;
    },

    // async findSpace(block) {
    //   if (block.isTerminal) {
    //     // find the max from openNeighbors
    //     let max = block.el.openNeighbors.reduce((max, b) => {
    //       return Math.max(max, b.el.space);
    //     }, 0);
    //     return max;
    //   } else {
    //     // find the min from openNeighbors
    //     let min = block.el.openNeighbors.reduce((min, b) => {
    //       return Math.min(min, b.el.space);
    //     }, 9999999);
    //     return min;
    //   }
    // },

    async findSpaces(endX = null, endY = null) {
      if (this.pathEnd.length === 0) {
        let space = this.open ? 1 : -1;
        this.setSpace(space);
        this.openNeighbors.forEach((n) => n.el.setSpace(1));
        return;
      }
      let checked = [];
      let blocksToCheck = [];
      this.resetSpaces();

      const keepFinding = async (block, index) => {
        await findMore(block.el.getN, index);
        await findMore(block.el.getS, index);
        await findMore(block.el.getE, index);
        await findMore(block.el.getW, index);
        return index;
      };

      const findMore = async (block, index) => {
        if (!block?.el) return;
        if (
          block.x > this.maxX ||
          block.y > this.maxY ||
          block.x < this.minX ||
          block.y < this.minY
        ) {
          block.el.setSpace(1);
          return 1;
        }
        if (!block.el.open) {
          block.el.setSpace(-1);
          return index;
        }

        // TODO - when having multiple spaces that can be passed through, how do you count them?
        if (block.el.space && block.el.space > index) {
          if (block.el.isTerminal) {
            block.el.setSpace(index + 1);
          } else {
            block.el.setSpace(index);
            return await keepFinding(block, index);
          }
        }

        if (checked.indexOf(block.i) !== -1) return index;
        checked.push(block.i);

        if (block.el.isPathEnd || block.el.hasPathEndNeighbor) {
          block.el.setSpace(index);
          return await keepFinding(block, index);
        }

        if (block.el.isTerminal) {
          if (
            !block.el.hasPathEndNeighbor &&
            block.el.connectorNeighbors.some((cn) => cn.el.space !== null)
          ) {
            index++;
            // SHORT CIRCUIT HERE
            block.el.setSpace(index);
            return index;
          }
          block.el.setSpace(index);
          return await keepFinding(block, index);
          // } else if (block.el.isDead) {
          //   block.el.setSpace(index);
          //   return index;
          // } else if (block.el.isConnector) {
          //   block.el.setSpace(index);
          //   return await keepFinding(block, index);
        } else {
          block.el.setSpace(index);
          return await keepFinding(block, index);
        }
      };

      if (endX === null) {
        endX = this.pathEnd[0];
        endY = this.pathEnd[1];
        endX = Math.min(this.maxX, endX);
        endY = Math.min(this.maxY, endY);
      }

      let block = this.getBlockByXY(endX, endY) || (this.openNeighbors && this.openNeighbors[0]);
      await findMore(block, 1);
    },
  },
};
</script>

<!-- <style lang="scss" src="./MazeBlock.scss" scoped></style> -->
