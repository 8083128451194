<template>
  <div class="Maze max-w-80vh mx-auto">
    <div class="p-5 text-xl hidden print:flex justify-between">
      <h1 class="font-bold">
        MazeMaker.app <small class="text-gray-400 font-normal">(v{{ pkg.version }})</small>
      </h1>
      <div class="space-x-7 flex">
        <div class="flex space-x-2">
          <small class="text-gray-400">Time</small>
          <strong
            class="flex justify-center items-center bg-black text-white rounded pt-1 h-7 w-7"
            >{{ time }}</strong
          >
        </div>
        <div class="flex space-x-2">
          <small class="text-gray-400">Difficulty</small>
          <strong
            class="flex justify-center items-center bg-black text-white rounded pt-1 h-7 w-7"
            >{{ difficulty }}</strong
          >
        </div>
      </div>
    </div>
    <div
      class="sm:flex items-center mt-5 mb-4 mx-5 space-y-3 sm:space-y-0 sm:justify-between print:hidden"
    >
      <button
        @click="
          version++;
          $nextTick(newMaze);
        "
        class="relative w-full sm:w-auto whitespace-nowrap bg-red-500 outline-none focus:ring ring-red-300 focus:outline-none text-white font-bold uppercase px-3 py-1"
      >
        <span class="relative z-20">Make Maze</span>
        <span v-if="ratingSuccess" class="absolute inset-0 bg-red-500 animate-ping z-10"></span>
      </button>
      <div class="flex items-center justify-center space-x-3 sm:pl-4">
        <small class="text-gray-400 w-14 sm:w-auto">Time</small>
        <button
          class="bg-green-200 outline-none focus:outline-none focus:ring ring-red-300 px-3 py-1 inline-flex items-center justify-center"
          @click="time = Math.max(1, time - 1)"
        >
          &ndash;
        </button>
        <strong class="w-5 sm:w-auto text-center">{{ time }}</strong>
        <button
          class="bg-green-200 outline-none focus:outline-none focus:ring ring-red-300 px-3 py-1 inline-flex items-center justify-center"
          @click="time = Math.min(5, time + 1)"
        >
          +
        </button>
      </div>
      <div class="flex items-center justify-center space-x-3 sm:pl-4">
        <small class="text-gray-400 w-14 sm:w-auto">Difficulty</small>
        <button
          class="bg-red-200 outline-none focus:outline-none focus:ring ring-red-300 px-3 py-1 inline-flex items-center justify-center"
          @click="difficulty = Math.max(1, difficulty - 1)"
        >
          &ndash;
        </button>
        <strong class="w-5 sm:w-auto text-center">{{ difficulty }}</strong>
        <button
          class="bg-red-200 outline-none focus:outline-none focus:ring ring-red-300 px-3 py-1 inline-flex items-center justify-center"
          @click="difficulty = Math.min(5, difficulty + 1)"
        >
          +
        </button>
      </div>
      <label class="block sm:flex items-center space-x-2 sm:pl-4 text-center" for="observe">
        <input type="checkbox" id="observe" v-model="observe" />
        <span>Observe</span>
      </label>
    </div>
    <div
      class="mx-5 relative text-right print:hidden duration-500"
      :class="isComplete || percentComplete === 0 ? 'opacity-0' : 'opacity-100'"
    >
      <strong class="px-2 absolute right-0 bottom-1 text-xs text-gray-400">
        {{ percentComplete.toFixed(1) }}% Complete
      </strong>
      <div class="h-1 w-full relative bg-gray-300">
        <div
          class="absolute inset-y-0 left-0 bg-red-500 duration-250"
          :style="{ width: `${percentComplete}%` }"
        ></div>
      </div>
    </div>

    <div
      class="grid mx-5 border-4 border-gray-500"
      :class="`grid-cols-${cols} grid-rows-${rows}`"
      :key="version"
    >
      <maze-block
        v-for="(block, i) in cols * rows"
        :ref="(el) => setBlockRef(el, i)"
        :add-to-path="(...args) => addToPath(paths.length - 1, ...args)"
        :get-block="(...args) => getBlock(i, ...args)"
        :get-block-by-x-y="getBlockByXY"
        :index="i"
        :very-end="veryEnd"
        :very-start="veryStart"
        :path-start="pathStart"
        :path-end="pathEnd"
        :last-path="lastPath"
        :level="level"
        :time="time"
        :difficulty="difficulty"
        :reset-spaces="resetSpaces"
        :show="observe || isComplete"
        :max-x="maxX"
        :max-y="maxY"
        :min-x="minX"
        :min-y="minY"
        :solution-is-complete="solutionIsComplete"
        :version="version"
        :on-block-trek="(ev) => onBlockTrek(i)"
        :debug="debug"
      />
    </div>

    <div v-if="mazeHash" class="mx-5 mt-1 text-xs text-right uppercase">
      <span>Unique Maze ID:</span>
      <strong class="ml-2">{{ mazeHash.slice(0, 6) }}</strong>
    </div>

    <div v-if="hasFinishedTrek" class="mx-5 mt-7 print:hidden flex items-center justify-between">
      <div class="font-bold text-3xl">Rate this maze!</div>
      <div v-if="ratingSubmitting" class="text-xl text-yellow-500 font-bold">Sending...</div>
      <div v-else-if="ratingSuccess" class="text-xl text-green-500 font-bold">Thanks!</div>
      <div v-else class="flex items-center text-yellow-500 space-x-5">
        <button
          @click="onRatingSubmit('too easy')"
          class="border-b border-transparent hover:border-current outline-none focus:outline-none"
        >
          Too Easy
        </button>
        <button
          @click="onRatingSubmit('perfect')"
          class="border-b border-transparent hover:border-current outline-none focus:outline-none"
        >
          Perfect
        </button>
        <button
          @click="onRatingSubmit('too hard')"
          class="border-b border-transparent hover:border-current outline-none focus:outline-none"
        >
          Too Hard
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import pkg from '../../package.json';
import MazeBlock from '@/components/MazeBlock';
import clone from '@/utils/object';
import { md5 } from '@/utils/md5';
import confetti from 'canvas-confetti';
import axios from 'axios';

const CARDS = ['N', 'S', 'E', 'W'];
const UNCARDS = ['S', 'N', 'W', 'E'];
const SEMICARDS = ['NE', 'NW', 'SE', 'SW'];

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default {
  name: 'Maze',

  components: {
    MazeBlock,
  },

  data() {
    return {
      pkg,
      debug: false,
      observe: false,
      blocks: [],
      paths: [[]],
      solutionBlocks: [],
      version: 0,
      isComplete: false,
      makingAt: null,
      ratingSubmitting: false,
      completedAt: null,
      finishedAt: null,
      startedAt: null,
      ratingSuccess: null,
      ratingError: null,
      rating: null,
      pathStart: [],
      blocksFilled: 0,
      percentComplete: 0,
      pathEnd: [],
      mazeHash: '',
      maxX: 0,
      maxY: 0,
      minX: 0,
      minY: 0,
      solutionIsComplete: false,
      time: parseInt(localStorage.getItem('mazemaker.time') || 2, 10),
      difficulty: parseInt(localStorage.getItem('mazemaker.difficulty') || 2, 10),
    };
  },

  computed: {
    level() {
      return this.time + this.difficulty;
    },

    cols() {
      return this.time * 5 + this.difficulty * 3;
    },

    rows() {
      return this.time * 5 + this.difficulty * 3;
    },

    lastPath() {
      return this.paths[this.paths.length - 1];
    },

    veryStart() {
      // TODO: make this better
      let x = this.difficulty * 2 - 2;
      let y = this.difficulty * 2 - 2;
      return [x, y];
    },

    veryEnd() {
      // TODO: make this better
      let x = this.cols - this.difficulty * 2 + 1;
      let y = this.rows - this.difficulty * 2 + 1;
      return [x, y];
    },

    veryStartBlock() {
      return this.getBlockByXY(this.veryStart[0], this.veryStart[1]);
    },

    veryEndBlock() {
      return this.getBlockByXY(this.veryEnd[0], this.veryEnd[1]);
    },

    isMaking() {
      return !this.isComplete && this.percentComplete > 0 && this.percentComplete < 100;
    },

    hasFinishedTrek() {
      return this.solutionBlocks?.every((b) => b?.el?.isTrekked);
    },
  },

  watch: {
    version(newVersion, oldVersion) {
      if (newVersion !== oldVersion) {
        this.reset();
      }
    },

    time(val, oldVal) {
      if (val !== oldVal) {
        localStorage.setItem('mazemaker.time', val);
        this.version++;
      }
    },

    difficulty(val, oldVal) {
      if (val !== oldVal) {
        localStorage.setItem('mazemaker.difficulty', val);
        this.version++;
      }
    },

    isComplete(val) {
      if (val) {
        this.percentComplete = 100;
        this.getMazeHash(this.getMazeBlocks());

        if (process.env.NODE_ENV !== 'production') return;
        this.completedAt = Date.now();
        let mazeBlocks = this.getMazeBlocks();
        let mazeHash = this.getMazeHash(mazeBlocks);
        let secondsToMake = Math.floor((this.completedAt - this.makingAt) / 100) / 10;
        let data = {
          mazeBlocks,
          mazeHash,
          makingAt: this.makingAt,
          completedAt: this.completedAt,
          secondsToMake,
          level_time: this.time,
          level_difficulty: this.difficulty,
        };
        axios.post('https://formspree.io/f/mnqljbko', { ...data, _gotcha: '' });
      }
    },

    hasFinishedTrek(val) {
      if (val) {
        this.solutionBlocks.forEach((b) => (b.el.isSolved = true));

        if (process.env.NODE_ENV !== 'production') return;
        this.finishedAt = Date.now();
        let mazeBlocks = this.getMazeBlocks();
        let mazeHash = this.getMazeHash(mazeBlocks);
        let secondsToFinish = Math.floor((this.finishedAt - this.startedAt) / 100) / 10;
        let data = {
          mazeHash,
          completedAt: this.completedAt,
          finishedAt: this.finishedAt,
          startedAt: this.startedAt,
          secondsToFinish,
          level_time: this.time,
          level_difficulty: this.difficulty,
        };
        axios.post('https://formspree.io/f/xvodzzjg', { ...data });
      }
    },

    mazeHash(val) {
      if (val) {
        document.title = `Maze ${val.slice(0, 6)} (Level ${this.time}/${
          this.difficulty
        }) // MazeMaker.app`;
      } else {
        document.title = `MazeMaker.app`;
      }
    },
  },

  beforeUnmount() {
    this.reset();
  },

  mounted() {
    this.newMaze();
  },

  methods: {
    getMazeBlocks() {
      return this.blocks.reduce((arr, block) => {
        let dirs = '';
        if (block.el.N) dirs += 'N';
        if (block.el.S) dirs += 'S';
        if (block.el.E) dirs += 'E';
        if (block.el.W) dirs += 'W';
        arr.push(dirs);
        return arr;
      }, []);
    },

    getMazeHash(mazeBlocks) {
      if (!this.mazeHash) {
        this.mazeHash = md5(JSON.stringify(mazeBlocks));
      }
      return this.mazeHash;
    },

    onBlockTrek(i) {
      if (!this.isComplete) return;
      let block = this.getBlock(i);

      if (block.el.hasTrekkedNeighborPath || block.el.isVeryStart || block.el.isVeryEnd) {
        block.el.isTrekked = true;
        if (this.startedAt === null && (this.veryStartBlock.i === i || this.veryEndBlock.i === i)) {
          this.startedAt = Date.now();
        }
      }

      this.$nextTick(() => {
        if (this.hasFinishedTrek) {
          if (this.solutionBlocks.filter((b) => b == block).length) {
            clearTimeout(this.celebrateTimeout);
            this.celebrateTimeout = setTimeout(() => {
              confetti();
            }, 100);
          }
        }
      });
    },

    resetSpaces() {
      this.blocks.forEach((b) => b.el.setSpace(null));
    },

    resetBlocks() {
      this.blocks.forEach((b) => b.el.reset());
    },

    reset() {
      this.resetBlocks();
      this.percentComplete = 0;
      this.blocksFilled = 0;
      this.blocks = [];
      this.makingAt = null;
      this.completedAt = null;
      this.finishedAt = null;
      this.startedAt = null;
      this.rating = null;
      this.ratingSuccess = null;
      this.ratingError = null;
      this.solutionBlocks = [this.veryStartBlock, this.veryEndBlock];
      this.paths = [[]];
      this.maxX = this.veryStart[0] + 2;
      this.maxY = this.veryStart[1] + 2;
      this.minX = this.veryStart[0] - 2;
      this.minY = this.veryStart[1] - 2;
      this.isComplete = false;
      this.solutionIsComplete = false;
      this.mazeHash = '';
    },

    sleep() {
      return new Promise(requestAnimationFrame);
    },

    newMaze() {
      this.version++;
      this.$nextTick(async () => {
        this.makingAt = Date.now();

        // return this.testPath();
        this.pathEnd = this.veryEnd;
        this.solutionBlocks = [this.veryStartBlock, this.veryEndBlock];

        // if (this.difficulty === 1) {
        //   await this.newPath(this.veryStart, this.veryEnd);
        // }

        // if (this.difficulty === 2) {
        //   this.newPath(this.veryEnd, this.veryStart);
        //   await this.sleep();
        //   this.newPath(this.veryEnd, this.veryStart);
        //   await this.sleep();
        // }

        await this.newPath(this.veryStart, this.veryEnd);
        this.solutionIsComplete = true;
        this.pathEnd = [];
        this.$nextTick(async () => {
          let startBlock, endBlock, dir;

          // Maybe branch from the start
          if (this.difficulty >= 4) {
            if (this.veryStartBlock.el.hasOpenNeighbors) {
              dir = this.veryStartBlock.el.hasOpenNeighbors[
                getRandomInt(0, this.veryStartBlock.el.hasOpenNeighbors.length - 1)
              ];
              startBlock = this.veryStartBlock.el[`get${dir}`];
            }
          }

          if (startBlock) {
            await this.newPath([startBlock.x, startBlock.y]);
            await this.veryStartBlock.el[`go${dir}`]();
          }

          // Maybe branch from the end
          if (this.difficulty >= 2) {
            if (this.veryEndBlock.el.hasOpenNeighbors) {
              dir = this.veryEndBlock.el.hasOpenNeighbors[
                getRandomInt(0, this.veryEndBlock.el.hasOpenNeighbors.length - 1)
              ];
              startBlock = this.veryEndBlock.el[`get${dir}`];
            }
          }

          if (startBlock) {
            await this.newPath([startBlock.x, startBlock.y]);
            await this.veryEndBlock.el[`go${dir}`]();
          }

          const makeBranches = async (blocks) => {
            // Now, branch from the path
            for (var i = 0; i < blocks.length; i++) {
              let block = blocks[i];
              if (!block?.el) continue;
              if (block.el.open) {
                let pathDir =
                  block.el.hasClosedNeighbors[
                    getRandomInt(0, block.el.hasClosedNeighbors.length - 1)
                  ];

                if (pathDir) {
                  await this.newPath([block.x, block.y]);
                  let blockIndex = this.paths.length - 1;
                  // Fixme: I'm not sure blockIndex is correct, but for now it doesn't matter
                  this.addToPath(blockIndex, pathDir, block);
                  await block.el[`go${pathDir}`]();
                  await this.sleep();
                }
              }
            }
          };

          // await makeBranches(this.blocks.filter((b) => b.el.isDead));
          // await makeBranches(
          //   this.blocks.filter((b) => b.el.isInnerPerimeter && b.el.hasClosedNeighbors),
          // );
          // await makeBranches(this.blocks.filter((b) => b.el.open && b.el.hasClosedNeighbors));
          await makeBranches(this.blocks.filter((b) => b.el.open));

          this.isComplete = true;
        });
      });
    },

    async testPath() {
      this.pathEnd = this.veryEnd;
      let [startX, startY] = this.veryStart;
      let block = this.getBlockByXY(startX, startY);
      // block = await block.el.goE();
      // await block.el.goE();
      // await block.el.getE.el.goW(false);
      let path = 'WNNEEESSSWNN'.split('');
      for (var i = 0; i < path.length; i++) {
        block = await block?.el[`go${path[i]}`]();
      }
      // await block.el.goE();
      await block.el.findSpaces();
      // block = await block.el.go();
      // block = await block.el.go();
      // await block.el.getE.el.goW(false);
      this.isComplete = true;
    },

    async newPath(start, end = null) {
      let [startX, startY] = start;
      this.pathStart = start;
      let [endX = null, endY = null] = end || [];
      this.pathEnd = end || [];

      const render = async (block) => {
        if (block.x === endX && block.y === endY) return true;
        let nextBlock = await block.el.go();
        await this.sleep();
        if (nextBlock?.el) {
          return await render(nextBlock);
        } else {
          return false;
        }
      };

      let startBlock = this.getBlockByXY(startX, startY);
      return await render(startBlock);
    },

    setBlockRef(el, i) {
      if (el) {
        let x = i % this.cols;
        let y = Math.floor(i / this.cols);
        this.blocks.push({ i, x, y, el });
      }
    },

    addToPath(index, dir, block) {
      // When index === length, we're starting a new path
      if (this.paths.length === index) {
        this.paths.push([]);
      }
      this.paths[index].push({ dir, block });

      // Update percent complete
      let total = this.cols * this.rows;
      this.percentComplete = ((this.blocksFilled + 1) / total) * 100;
      this.blocksFilled += 1;

      // Update maxX and maxY
      this.maxY = Math.max(this.maxY, block.y + 2);
      this.maxX = Math.max(this.maxX, block.x + 2);
      this.minY = Math.min(this.minY, block.y - 2);
      this.minX = Math.min(this.minX, block.x - 2);

      // Add to solutionBlocks
      if (!this.solutionIsComplete) {
        this.solutionBlocks.push(block);
      }
    },

    getDirection(fromBlock, toBlock) {
      let path = '';
      if (toBlock.y > fromBlock.y) {
        path += 'N';
      } else if (toBlock.y < fromBlock.y) {
        path += 'S';
      }
      if (toBlock.x > fromBlock.x) {
        path += 'E';
      } else if (toBlock.x < fromBlock.x) {
        path += 'W';
      }
      return path;
    },

    getBlockByXY(x, y) {
      let index = y * this.cols + x;
      return this.blocks[index];
    },

    getBlock(i, xMod = 0, yMod = 0) {
      let block = this.blocks[i];
      if (!block) return null;

      if (block.x + xMod < 0) return null;
      if (block.x + xMod > this.cols - 1) return null;
      if (block.y + yMod < 0) return null;
      if (block.y + yMod > this.rows - 1) return null;

      // I should not be able to go off the left and come in on the right or vice versa
      let newI = i + xMod + yMod * this.cols;
      return newI >= 0 && newI <= this.blocks.length ? this.blocks[newI] : null;
    },

    onRatingSubmit(rating) {
      if (process.env.NODE_ENV !== 'production') return;
      let mazeBlocks = this.getMazeBlocks();
      let mazeHash = this.getMazeHash(mazeBlocks);
      this.rating = rating;
      this.ratingSubmitting = true;
      axios
        .post('https://formspree.io/f/xwkavvdv', {
          mazeHash,
          rating,
          level_time: this.time,
          level_difficulty: this.difficulty,
        })
        .then(({ data }) => {
          if (data.ok) {
            this.ratingSuccess = true;
          } else {
            this.ratingSuccess = false;
          }
        })
        .catch((err) => {
          this.ratingSuccess = false;
          this.ratingError = err;
        })
        .finally(() => {
          this.ratingSubmitting = false;
        });
    },
  },
};
</script>

<!-- <style lang="scss" src="./Maze.scss" scoped></style> -->
